import { inject, Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { NavigationNode } from '../types/navigation'

@Injectable({
    providedIn: 'root',
})
export class NavigationBarService {
    private _navigationNodes: NavigationNode[] = []

    private _isSimplifiedNavigation$ = new BehaviorSubject<boolean>(true)

    private _focusedRootNode$ = new Subject<NavigationNode | null>()

    private _activeNode = new BehaviorSubject<NavigationNode | null>(null)

    private _activeChildNodes = new BehaviorSubject<NavigationNode[]>([])

    private _onNodeClick = new Subject<NavigationNode>()

    private _onChangeRootNodesVisibility$ = new Subject<string[]>()
    public onChangeRootNodesVisibility$: Observable<string[]> = this._onChangeRootNodesVisibility$.asObservable()

    private focusedNodeTimeout: any = null

    get isSimplifiedNavigation(): Observable<boolean> {
        return this._isSimplifiedNavigation$.asObservable()
    }

    get focusedRootNode(): Observable<NavigationNode | null> {
        return this._focusedRootNode$.asObservable()
    }

    get onNodeClick(): Observable<NavigationNode> {
        return this._onNodeClick.asObservable()
    }

    get activeNode(): Observable<NavigationNode | null> {
        return this._activeNode.asObservable()
    }

    get activeNodeChildren(): Observable<NavigationNode[]> {
        return this._activeChildNodes.asObservable()
    }

    getActiveChildren() {
        return this._activeChildNodes.getValue()
    }

    initializeAvailableNavigationNodes(nodes: NavigationNode[]) {
        this._navigationNodes = nodes
    }

    setFocusedRootNode(node: NavigationNode) {
        this.stopFocusedNodeTimeout()
        this._focusedRootNode$.next(node)
    }

    clearFocusedRootNode() {
        if (!this.focusedNodeTimeout) {
            this.focusedNodeTimeout = setInterval(() => {
                this._focusedRootNode$.next(null)
                this.stopFocusedNodeTimeout()
            }, 200)
        } else {
            this.stopFocusedNodeTimeout()
            this._focusedRootNode$.next(null)
        }
    }

    stopFocusedNodeTimeout() {
        if (this.focusedNodeTimeout) {
            clearInterval(this.focusedNodeTimeout)
            this.focusedNodeTimeout = null
        }
    }

    toggleSimplifiedNavigation(isSimplified: boolean) {
        this._isSimplifiedNavigation$.next(isSimplified)
    }

    dispatchNodeClick(node: NavigationNode) {
        this._onNodeClick.next(node)
    }

    setActiveNode(node: NavigationNode | null) {
        if (node) {
            this._activeNode.next(this.findRootNode(node) || null)
            this._activeChildNodes.next(this.getActiveNodes(node))
        } else {
            const homeNode = this.getNodeByUrl('home') ?? null
            this._activeNode.next(homeNode ?? null)
            this._activeChildNodes.next([])
        }
    }

    public getActiveNodes(childNode: NavigationNode): NavigationNode[] {
        const activeNodes: NavigationNode[] = []

        let currentNode: NavigationNode | undefined = childNode
        while (currentNode) {
            activeNodes.unshift(currentNode)
            currentNode = this.findParentNode(currentNode)
        }

        const rootNode = this.findRootNode(childNode)
        if (rootNode && !activeNodes.includes(rootNode)) {
            activeNodes.unshift(rootNode)
        }

        return activeNodes
    }

    public getActiveRootNodeByUrl(url: string): NavigationNode | undefined {
        const foundedNode = this.findNodeByUrl(this._navigationNodes, url)

        if (foundedNode) {
            return this.findRootNode(foundedNode)
        }

        return undefined
    }

    public getNodeByUrl(url: string): NavigationNode | undefined {
        return this.findNodeByUrl(this._navigationNodes, url)
    }

    public nodeIsActive(node: NavigationNode): boolean {
        const activeNode = this._activeNode.getValue()
        if (activeNode) {
            return this.getActiveNodes(activeNode).some(activeNode => activeNode.label === node.label)
        }

        return false
    }

    private findParentNode(node: NavigationNode): NavigationNode | undefined {
        if (!node.parent) return undefined
        return this.findNodeByLabel(this._navigationNodes, node.parent)
    }

    private findNodeByLabel(nodes: NavigationNode[], label: string): NavigationNode | undefined {
        for (const node of nodes) {
            if (node.label.toLowerCase() === label.toLowerCase()) {
                return node
            } else if (node.nodes.length > 0) {
                const foundNode = this.findNodeByLabel(node.nodes, label)
                if (foundNode) return foundNode
            }
        }
        return undefined
    }

    private findNodeByUrl(nodes: NavigationNode[], url: string): NavigationNode | undefined {
        for (const node of nodes) {
            if (node.url && url.includes(node.url)) {
                return node
            }

            if (node.nodes && node.nodes.length > 0) {
                const foundNode = this.findNodeByUrl(node.nodes, url)
                if (foundNode) {
                    return foundNode
                }
            }
        }

        return undefined
    }

    private findRootNode(node: NavigationNode): NavigationNode | undefined {
        let currentNode: NavigationNode | undefined = node
        while (currentNode && !(currentNode.isRoot && currentNode.parent === undefined)) {
            currentNode = this.findParentNode(currentNode)
        }
        return currentNode
    }

    setRootNodesVisibility(visibleNodes: string[] = []) {
        this._onChangeRootNodesVisibility$.next(visibleNodes)
    }
}
